import urls from '@/constants/endpoints/gateway';
import {DATE_FORMAT_API} from '@/constants/timeFormats';
import {
    AddActivitiesFromHandoffRequestDTO,
    AddUnplannedActivitiesRequestDTO,
    AddUnplannedActivitiesResponseDTO,
    AppointmentCalendarFilterItemDTO,
    AppointmentCalendarResponseDTO,
    AppointmentCancellationRequestDTO,
    AppointmentChangeVendorRequestDTO,
    AppointmentItemDTO,
    AppointmentNoteDTO,
    AppointmentStatusHistoryDTO,
    AppointmentUncancellationRequestDTO,
    CheckInDTO,
    CheckInResponseDTO,
    CheckoutDataResponseDTO,
    ConfirmInHomeAppointmentRequestDTO,
    CreatedResponseDTO,
    CreateGeneralAppointmentRequestDTO,
    CreateInHomeAppointmentRequestDTO,
    CreateMultiDayInHomeAppointmentRequestDTO,
    DocumentInHomeAppointmentRequestDTO,
    EligibleVendorActorsSearchResponseDTO,
    GeneralAppointmentResponseDTO,
    HandOffStatus,
    IdResponseWithNotificationIdDTO,
    InHomeAppointmentAgendaDetailsDTO,
    InHomeAppointmentCalendarDetailsDTO,
    InHomeAppointmentCheckoutDTO,
    InHomeAppointmentConfirmResponseDTO,
    InHomeAppointmentDocumentationStatusResponseDto,
    InHomeAppointmentDocumentResponseDTO,
    InHomeAppointmentItemResponseDTO,
    InHomeAppointmentMissedCheckInOutDTO,
    InHomeAppointmentResponseDTO,
    InHomeAppointmentUnplannedActivitiesResponseDTO,
    InHomeDetailsResponseDTO,
    ModificationRequestActivityDetailsModel,
    PageAppointmentModificationRequestListDTO,
    PersonnelPatientsAppointmentsCountResponseDTO,
    PersonnelPatientsAppointmentsResponseDTO,
    PostponedNotificationDTO,
    PostponedNotificationIdResponseDTO,
    ReasonOptionDTO,
    ReviewSelfCheckInDTO,
    ReviewSelfCheckOutDTO,
    SelfCheckInReviewRequestDTO,
    SelfCheckOutReviewRequestDTO,
    SimpleMessageResponseDTO,
    SimpleMessageResponseWithNotificationIdDTO,
    UnavailableServiceProviderRequestDTO,
    UnplannedActivityDTO,
    UpdateAppointmentRequestDTO,
    UpdateInHomeAppointmentMissedCheckInOutDTO,
    UpdateInHomeAppointmentRequestDTO,
    VendorCalendarRequestDTO,
    VendorCalendarResponseDTO,
    VendorStatus,
} from '@/types/gatewayDataModels';
import {formatDateTime} from '@/utils/timeFormatter';

import httpApi, {QueryParams} from './httpApi_new';

class AppointmentsService {
    getAppointments = ({
        date,
        type,
        actor,
        patientId,
        description,
        includeRemoved,
    }: {
        date: string;
        type?: string;
        patientId: number;
        description?: string;
        includeRemoved?: boolean;
        actor?: {codeSet: string; code: string};
    }): Promise<AppointmentItemDTO[]> => {
        const queryParams = {
            date: formatDateTime(date, DATE_FORMAT_API),
            patientId,
            ...(actor && {actor: `${actor.codeSet}/${actor.code}`}),
            ...(description && {description}),
            ...(type && {type}),
            includeRemoved,
        };
        return httpApi.get({
            url: urls.APPOINTMENTS,
            queryParams,
        });
    };

    getAppointmentsTypes = (): Promise<('General' | 'In Home')[]> => httpApi.get({url: urls.APPOINTMENTS_TYPES});

    getAppointmentDetails = ({appointmentId}: {appointmentId: number}): Promise<GeneralAppointmentResponseDTO> =>
        httpApi.get({url: `${urls.APPOINTMENT_GENERAL}/${appointmentId}`});

    addAppointment = ({
        patientId,
        formData,
    }: {
        patientId: number;
        formData: CreateGeneralAppointmentRequestDTO;
    }): Promise<{[key: string]: number[]}> =>
        httpApi.post({
            url: urls.APPOINTMENT_GENERAL,
            queryParams: {patientId},
            data: formData,
        });

    editAppointment = ({
        formData,
    }: {
        formData: UpdateAppointmentRequestDTO & {id: number};
    }): Promise<{[key: string]: number[]}> =>
        httpApi.put({
            url: `${urls.APPOINTMENT_GENERAL}/${formData.id}`,
            data: formData,
        });
    autosaveAppointment = (data: {
        activityIds: number[];
        clinicalWindowStart: string;
        clinicalWindowEnd: string;
        timeZone: string;
    }): Promise<{[key: string]: string}> =>
        httpApi.post({
            url: `${urls.APPOINTMENT_AUTOSAVE}`,
            data,
        });
    createAppointmentInHome = ({data}: {data: CreateInHomeAppointmentRequestDTO}): Promise<CreatedResponseDTO> =>
        httpApi.post({
            url: urls.APPOINTMENTS_IN_HOME,
            data,
        });

    addActivitiesFromServiceRequest = (data: AddActivitiesFromHandoffRequestDTO): Promise<number[]> =>
        httpApi.put({
            url: urls.ADD_ACTIVITIES_FROM_SERVICE_REQUEST,
            data,
        });

    createAppointmentInHomeMultiple = ({
        data,
    }: {
        data: CreateMultiDayInHomeAppointmentRequestDTO;
    }): Promise<CreatedResponseDTO[]> =>
        httpApi.post({
            url: urls.APPOINTMENT_IN_HOME_MULTIPLE,
            data,
        });

    getAppointmentDocument = (id: number): Promise<InHomeAppointmentDocumentResponseDTO> =>
        httpApi.get({url: urls.APPOINTMENT_IN_HOME_DOCUMENT(id)});

    putAppointmentDocument = ({
        id,
        data,
    }: {
        id: number;
        data: DocumentInHomeAppointmentRequestDTO;
    }): Promise<InHomeAppointmentDocumentationStatusResponseDto> =>
        httpApi.put({
            url: urls.APPOINTMENT_IN_HOME_DOCUMENT(id),
            data: data,
        });

    getAppointmentConfirmation = ({id}: {id: number}): Promise<InHomeAppointmentConfirmResponseDTO> =>
        httpApi.get({url: urls.APPOINTMENT_IN_HOME_CONFIRM(id)});

    confirmAppointment = ({
        data,
        id,
    }: {
        id: number;
        data: ConfirmInHomeAppointmentRequestDTO;
    }): Promise<CreatedResponseDTO> =>
        httpApi.put({
            url: urls.APPOINTMENT_IN_HOME_CONFIRM(id),
            data,
        });

    getAppointmentCheckOut = (id: number): Promise<CheckoutDataResponseDTO> =>
        httpApi.get({url: urls.APPOINTMENT_CHECK_OUT(id)});

    putAppointmentCheckOut = ({
        id,
        data,
        disableGeneralNotify,
    }: {
        id: number;
        data: InHomeAppointmentCheckoutDTO;
        disableGeneralNotify?: (value: unknown) => boolean;
    }): Promise<SimpleMessageResponseDTO> =>
        httpApi.put({
            url: urls.APPOINTMENT_CHECK_OUT(id),
            data: data,
            disableGeneralNotify,
        });

    getAppointmentByDate = ({
        apiDate,
        patientId,
    }: {
        apiDate: string;
        patientId: number;
    }): Promise<AppointmentItemDTO[]> =>
        httpApi.get({url: urls.APPOINTMENTS_BY_DATE, queryParams: {date: apiDate, patientId}});

    updateOrderAdditionalActivities = ({
        id,
        data,
    }: {
        id: number;
        data: AddUnplannedActivitiesRequestDTO;
    }): Promise<AddUnplannedActivitiesResponseDTO> =>
        httpApi.put({
            url: urls.ORDER_UNPLANNED_ACTIVITIES(id),
            data,
        });

    getAdditionalActivities = ({id}: {id: number}): Promise<InHomeAppointmentUnplannedActivitiesResponseDTO> =>
        httpApi.get({url: urls.APPOINTMENTS_UNPLANNED(id)});

    getAppointmentCancelReasons = (): Promise<ReasonOptionDTO[]> =>
        httpApi.get({url: urls.APPOINTMENTS_CANCEL_REASONS});

    markAppointmentUnavailable = ({id, data}: {id: number; data: UnavailableServiceProviderRequestDTO}) =>
        httpApi.put({
            url: urls.APPOINTMENT_mark_unavailable_loading(id),
            data,
        });

    getInHomeAppointment = (id: number): Promise<InHomeAppointmentResponseDTO> =>
        httpApi.get({url: urls.APPOINTMENT_IN_HOME(id)});

    getInHomeAppointments = (appointmentIds: number[]): Promise<InHomeAppointmentResponseDTO[]> =>
        httpApi.get({url: urls.APPOINTMENTS_IN_HOME, queryParams: {appointmentIds}});

    updateInHomeAppointment = ({
        id,
        data,
    }: {
        id: number;
        data: UpdateInHomeAppointmentRequestDTO;
    }): Promise<IdResponseWithNotificationIdDTO> =>
        httpApi.put({
            url: urls.APPOINTMENT_IN_HOME(id),
            data: data,
        });

    updateMissedInHomeAppointment = ({
        id,
        data,
    }: {
        id: number;
        data: UpdateInHomeAppointmentMissedCheckInOutDTO;
    }): Promise<SimpleMessageResponseDTO> =>
        httpApi.put({
            url: urls.APPOINTMENT_MISSED_IN_HOME(id),
            data: data,
        });

    getAppointmentUncancelReasonOptions = (): Promise<
        (
            | 'New/replaced Orders'
            | 'Change in Vendor Availability'
            | 'Other Clinical Reason'
            | 'Other Non-Clinical Reason'
        )[]
    > => httpApi.get({url: urls.APPOINTMENT_UNCANCEL_REASON_OPTIONS});

    cancelAppointment = (
        id: number,
        data: AppointmentCancellationRequestDTO,
    ): Promise<SimpleMessageResponseWithNotificationIdDTO> =>
        httpApi.put({
            url: urls.APPOINTMENT_CANCEL(id),
            data: data,
        });

    unCancelAppointment = (id: number, data: AppointmentUncancellationRequestDTO): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({
            url: urls.APPOINTMENT_UNCANCEL(id),
            data,
        });

    deleteGeneralAppointment = (id: number): Promise<{[key: string]: number[]}> =>
        httpApi.delete({url: urls.APPOINTMENT_GENERAL_DELETE(id)});

    deleteInHomeAppointment = (id: number): Promise<unknown> =>
        httpApi.put({url: urls.APPOINTMENTS_IN_HOME_DELETE(id)});

    getAppointmentCheckIn = (id: number): Promise<CheckInResponseDTO> =>
        httpApi.get({url: urls.APPOINTMENT_CHECK_IN_GET_CHECKLIST(id)});

    getAppointmentReviewSelfCheckIn = (appointmentId: number): Promise<ReviewSelfCheckInDTO> =>
        httpApi.get({url: urls.APPOINTMENT_SELF_CHECK_IN_REVIEW_DETAILS(appointmentId)});

    getAppointmentReviewSelfCheckOut = (appointmentId: number): Promise<ReviewSelfCheckOutDTO> =>
        httpApi.get({url: urls.APPOINTMENT_SELF_CHECK_OUT_REVIEW_DETAILS(appointmentId)});

    checkInAppointmentConfirm = ({
        dataToApi,
        activeAppointmentId,
        disableGeneralNotify,
    }: {
        dataToApi: CheckInDTO;
        activeAppointmentId: number;
        disableGeneralNotify?: (value: unknown) => boolean;
    }): Promise<PostponedNotificationIdResponseDTO> =>
        httpApi.put({
            url: urls.APPOINTMENT_CHECK_IN_CONFIRM(activeAppointmentId),
            disableGeneralNotify,
            data: dataToApi,
        });

    undoCheckIn = ({id, comment}: {id: number; comment?: string}): Promise<unknown> =>
        httpApi.put({
            url: urls.APPOINTMENT_UNDO_CHECK_IN(id),
            data: {comment},
        });

    undoCheckOut = ({id, comment}: {id: number; comment?: string}): Promise<unknown> =>
        httpApi.put({
            url: urls.APPOINTMENT_UNDO_CHECK_OUT(id),
            data: {comment},
        });

    undoConfirm = ({id, comment}: {id: number; comment?: string}): Promise<unknown> =>
        httpApi.put({
            url: urls.APPOINTMENT_UNDO_CONFIRM(id),
            data: {comment},
        });

    getAppointmentsHistory = (id: number): Promise<AppointmentStatusHistoryDTO[]> =>
        httpApi.get({
            url: urls.APPOINTMENTS_HISTORY(id),
        });

    getAppointmentEligibleActors = (appointmentId: number): Promise<EligibleVendorActorsSearchResponseDTO[]> =>
        httpApi.get({url: urls.APPOINTMENT_ELIGIBLE_ACTORS(appointmentId)});

    changeAppointmentVendor = ({
        appointmentId,
        data,
    }: {
        appointmentId: number;
        data: AppointmentChangeVendorRequestDTO;
    }): Promise<IdResponseWithNotificationIdDTO> =>
        httpApi.put({
            url: urls.APPOINTMENT_CHANGE_VENDOR(appointmentId),
            data,
        });

    getAppointmentAcrossPatients = (queryParams: {
        date: string;
        podIds?: number[];
        personnelIds?: number[];
        includeDemoPatients?: boolean;
    }): Promise<AppointmentCalendarResponseDTO> => httpApi.get({url: urls.APPOINTMENT_CALENDAR, queryParams});

    getAppointmentsAcrossVendors = (
        body: VendorCalendarRequestDTO,
        queryParams: QueryParams,
    ): Promise<VendorCalendarResponseDTO> =>
        httpApi.post({url: urls.VENDORS_APPOINTMENT_CALENDAR, data: body, queryParams});

    getInHomeAppointmentOverview = (id: number): Promise<InHomeAppointmentItemResponseDTO> =>
        httpApi.get({url: urls.APPOINTMENT_IN_HOME_OVERVIEW(id)});

    getAgendaInHomeAppointmentOverview = (id: number): Promise<InHomeAppointmentAgendaDetailsDTO> =>
        httpApi.get({url: urls.AGENDA_APPOINTMENT_IN_HOME_OVERVIEW(id)});

    getInHomeAppointmentCalendarOverview = (id: number): Promise<InHomeAppointmentCalendarDetailsDTO> =>
        httpApi.get({url: urls.APPOINTMENT_IN_HOME_CALENDAR_OVERVIEW(id)});

    getGeneralAppointmentOverview = (id: number): Promise<GeneralAppointmentResponseDTO> =>
        httpApi.get({url: urls.APPOINTMENT_GENERAL_OVERVIEW(id)});

    getAppointmentPatientFilters = (): Promise<AppointmentCalendarFilterItemDTO[]> =>
        httpApi.get({url: urls.APPOINTMENT_CALENDAR_PATIENT_FILTER_ITEMS});

    getInHomeAppointmentStateGroups = (): Promise<string[]> =>
        httpApi.get({url: urls.IN_HOME_APPOINTMENT_STATE_GROUPS});

    missedCheckInCheckOut = ({
        appointmentId,
        data,
    }: {
        appointmentId: number;
        data: InHomeAppointmentMissedCheckInOutDTO;
    }): Promise<SimpleMessageResponseDTO> =>
        httpApi.put({
            url: urls.APPOINTMENTS_MISSED_CHECK_IN_CHECK_OUT(appointmentId),
            data,
        });

    getAppointmentsMiniNote = (ids: number[]): Promise<string[]> =>
        httpApi.get({url: urls.APPOINTMENTS_MINI_NOTES, queryParams: {ids}});

    saveAppointmentScratchPad = (id: number, data: AppointmentNoteDTO): Promise<SimpleMessageResponseDTO> =>
        httpApi.put({
            url: urls.APPOINTMENT_NOTE(id),
            data,
        });
    getAppointmentScratchPad = (id: number, props?: {disableGeneralNotify: boolean}): Promise<{text: string}> =>
        httpApi.get({url: urls.APPOINTMENT_NOTE(id), disableGeneralNotify: () => props?.disableGeneralNotify});

    saveGeneralAppointmentScratchPad = (id: number, data: AppointmentNoteDTO): Promise<SimpleMessageResponseDTO> =>
        httpApi.put({
            url: urls.GENERAL_APPOINTMENT_NOTE(id),
            data,
        });
    getGeneralAppointmentScratchPad = (id: number, props?: {disableGeneralNotify: boolean}): Promise<{text: string}> =>
        httpApi.get({url: urls.GENERAL_APPOINTMENT_NOTE(id), disableGeneralNotify: () => props?.disableGeneralNotify});

    getMyPageAppointments = ({
        date,
        timeZone,
        personnelId,
    }: {
        date: string;
        timeZone: string;
        personnelId: number;
    }): Promise<PersonnelPatientsAppointmentsResponseDTO[]> =>
        httpApi.get({
            url: urls.MY_PAGE_APPOINTMENTS_AGENDA,
            queryParams: {date, personnelId, timeZone},
        });

    getAgendaCountByNurse = ({
        date,
        timeZone,
        personnelId,
    }: {
        date: string;
        timeZone: string;
        personnelId: number;
    }): Promise<PersonnelPatientsAppointmentsCountResponseDTO> =>
        httpApi.get({
            url: urls.MY_PAGE_AGENDA_COUNT,
            queryParams: {date, personnelId, timeZone},
        });

    addSelfCheckInActivities = ({
        id,
        activityIds,
    }: {
        id: number;
        activityIds?: number[];
    }): Promise<UnplannedActivityDTO[]> =>
        httpApi.put({
            url: urls.ADD_SELF_CHECK_IN_ACTIVITIES(id),
            data: {activityIds},
        });

    addSelfCheckOutActivities = ({
        id,
        activityIds,
    }: {
        id: number;
        activityIds?: number[];
    }): Promise<UnplannedActivityDTO[]> =>
        httpApi.put({
            url: urls.ADD_SELF_CHECK_OUT_ACTIVITIES(id),
            data: {activityIds},
        });

    saveReviewCheckIn = (
        id: number,
        data: SelfCheckInReviewRequestDTO,
    ): Promise<SimpleMessageResponseWithNotificationIdDTO> =>
        httpApi.post({
            url: urls.SAVE_REVIEW_CHECK_IN(id),
            data,
        });

    saveReviewCheckOut = (id: number, data: SelfCheckOutReviewRequestDTO): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({
            url: urls.SAVE_REVIEW_CHECK_OUT(id),
            data,
        });

    voidReviewCheckIn = (id: number, data: SelfCheckInReviewRequestDTO): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({
            url: urls.VOID_REVIEW_CHECK_IN(id),
            data,
        });

    voidReviewCheckOut = (id: number, data: SelfCheckOutReviewRequestDTO): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({
            url: urls.VOID_REVIEW_CHECK_OUT(id),
            data,
        });

    getArrivalWindowTimeSlots = (): Promise<string[]> =>
        httpApi.get({
            url: urls.CUSTOM_ARRIVAL_WINDOW_DURATIONS,
        });

    getAttendeeList = (): Promise<
        (
            | 'Mission Control Nurse'
            | 'Mission Control NP'
            | 'Mission Control PA'
            | 'Mission Control Physician'
            | 'Nurse Practitioner'
            | 'Physician'
        )[]
    > =>
        httpApi.get({
            url: urls.APPOINTMENT_GENERAL_ATTENDEE,
        });

    getAppointmentPotentialIssue = (id: number): Promise<string[]> =>
        httpApi.get({
            url: urls.APPOINTMENT_POTENTIAL_ISSUE(id),
        });

    getPerformanceImprovementLogAppointmentDetails = (appointmentId: number): Promise<InHomeDetailsResponseDTO> =>
        httpApi.get({
            url: urls.PERFORMANCE_IMPROVEMENT_LOG_APPOINTMENT_DETAILS(appointmentId),
        });

    getPostponedNotification = (notificationId: string): Promise<PostponedNotificationDTO> =>
        httpApi.get({
            url: urls.POSTPONED_NOTIFICATION(notificationId),
        });

    getModificationRequests = (queryParams?: {
        statuses?: HandOffStatus[];
        vendorStatuses?: VendorStatus[];
        sponsors?: string[];
        serviceCoordinators?: number[];
        pods?: number[];
        siteCodes?: string[];
        includeDemo?: boolean;
        page?: number;
        size?: number;
        sort?: string[];
    }) => httpApi.get<PageAppointmentModificationRequestListDTO>({url: urls.MODIFICATIONS, queryParams});

    getModificationRequestDetails = (id: number) =>
        httpApi.get<ModificationRequestActivityDetailsModel[]>({url: urls.MODIFICATION_DETAILS(id)});
}

const appointmentsService = new AppointmentsService();
export default appointmentsService;
