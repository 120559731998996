import urls from '@/constants/endpoints/gateway';
import {
    AssignmentCheckDTO,
    AvailableAssignmentsDTO,
    CreatedResponseDTO,
    DischargeDestinationDTO,
    EquipmentKitResponseDTO,
    MultiAssignmentCreateDTO,
    PagePatientAssignmentDTO,
    PagePatientDropdownDataDTO,
    PagePatientOverviewResponseDTO,
    PageVisitLogDTO,
    PatientActivateDTO,
    PatientAvailableAssignmentsDTO,
    PatientBasicDataDTO,
    PatientDeactivationInfo,
    PatientDischargeRequestV2DTO,
    PatientDTO,
    PatientForVendorSearchDTO,
    PatientPrecautionCreateDTO,
    PatientPrecautionDTO,
    PatientPrecautionHistoryResponseDTO,
    PatientPrecautionOverviewDTO,
    PatientPrecautionUpdateDTO,
    PatientResponseDTO,
    PatientScratchpadCreateDTO,
    PatientScratchpadDTO,
    PatientScratchpadHistoryDTO,
    PersonnelDTO,
    PrecautionDTO,
    ShiftAssignmentHistoryRecordDTO,
    SimpleMessageResponseDTO,
    TimeZoneDTO,
    VisitLogRequestDTO,
    VoidDetailsDTO,
    VoidedDetailsDTO,
    VoidedReason,
} from '@/types/gatewayDataModels';

import httpApi from './httpApi_new';

class PatientService {
    getPatient = (params: {patientId: number}): Promise<PatientResponseDTO> =>
        httpApi.get({url: `${urls.PATIENTS}/${params.patientId}`});

    getDraftPatient = (params: {patientId: number}): Promise<PatientResponseDTO> =>
        httpApi.get({url: `${urls.PATIENTS}/${params.patientId}/draft`});

    updatePatient = ({patientId, data}: {patientId: number; data: PatientDTO}): Promise<PatientResponseDTO> =>
        httpApi.put({url: `${urls.PATIENTS}/${patientId}`, data});

    verifyPatient = ({
        patientId,
        data,
        isDemo,
    }: {
        patientId: number;
        data: PatientDTO;
        isDemo: boolean;
    }): Promise<PatientResponseDTO> =>
        httpApi.patch({url: `${urls.PATIENTS}/${patientId}/verify?isDemo=${isDemo}`, data});

    savePatientInfo = ({
        patientId,
        data,
        isDemo,
    }: {
        patientId: number;
        data: PatientDTO;
        isDemo: boolean;
    }): Promise<PatientResponseDTO> =>
        httpApi.patch({url: `${urls.PATIENTS}/${patientId}/draft?isDemo=${isDemo}`, data});

    getPatients = (queryParams: {
        inactive?: boolean;
        hideDischargedPatients?: boolean;
        showDemo?: boolean;
        showDischarged?: boolean;
        assignedToMe?: boolean;
        voided?: boolean;
        products?: string;
        sponsors?: string;
        query?: string;
        dateRangeFrom?: string;
        dateRangeTo?: string;
        clusterId?: number;
        namePart?: string;
        page?: number;
        size?: number;
        sort?: string;
    }): Promise<PagePatientOverviewResponseDTO> =>
        httpApi.get({
            url: urls.PATIENTS,
            queryParams,
        });

    getPatientKits = (
        {patientId}: {patientId: number},
        isTechKitsEnabled = true,
    ): Promise<EquipmentKitResponseDTO[]> =>
        isTechKitsEnabled
            ? httpApi.get({url: urls.PATIENT_INVENTORY(patientId)})
            : Promise.resolve([] as EquipmentKitResponseDTO[]);

    assignNurse = (queryParams: {patientId: number; personnelId: number}): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({url: urls.SHIFT_ASSIGMENTS, queryParams});

    assignMultipleNurses = (data: MultiAssignmentCreateDTO): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({
            url: urls.SHIFT_ASSIGMENTS_MULTIPLE,
            data,
        });

    getPatientScratchPad = (scratchPadId: number): Promise<PatientScratchpadDTO> =>
        httpApi.get({url: urls.PATIENT_SCRATCHPAD_BY_ID(scratchPadId)});

    getPatientScratchPads = (patientId: number): Promise<PatientScratchpadHistoryDTO[]> =>
        httpApi.get({url: urls.PATIENT_SCRATCHPADS_BY_ID(patientId)});

    getPatientLastScratchPad = (patientId: number): Promise<PatientScratchpadDTO> =>
        httpApi.get({url: urls.PATIENT_LAST_SCRATCHPAD_BY_ID(patientId)});

    updatePatientScratchPad = (data: PatientScratchpadCreateDTO): Promise<PatientScratchpadDTO> =>
        httpApi.post({url: urls.PATIENT_SCRATCHPAD, data});

    voidPatient = ({patientId, data}: {patientId: number; data: VoidedDetailsDTO}): Promise<unknown> =>
        httpApi.patch({url: urls.VOID_PATIENT(patientId), data});

    dischargePatient = ({
        patientId,
        data,
    }: {
        patientId: number;
        data: PatientDischargeRequestV2DTO;
    }): Promise<SimpleMessageResponseDTO> => httpApi.patch({url: urls.DISCHARGE_PATIENT(patientId), data});

    unDischargePatient = (patientId: number): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.UNDISCHARGE_PATIENT(patientId)});

    getDischargeDestinations = (): Promise<DischargeDestinationDTO[]> =>
        httpApi.get({url: urls.DISCHARGE_DESTINATIONS});

    saveVisitLog = ({
        data,
        patientId,
    }: {
        data: VisitLogRequestDTO;
        patientId: number;
    }): Promise<SimpleMessageResponseDTO> => httpApi.post({url: urls.VISIT_LOG(patientId), data});

    getUserLogs = ({
        page,
        size,
        sort,
        dateFrom,
        dateTo,
        patientId,
        personnelId,
    }: {
        page?: number;
        size?: number;
        personnelId?: number;
        patientId?: number;
        dateFrom?: string;
        dateTo?: string;
        sort: string[];
    }): Promise<PageVisitLogDTO> =>
        httpApi.get({
            url: urls.USERS_LOG,
            queryParams: {
                page,
                size,
                ...(dateFrom && dateTo && {dateFrom, dateTo}),
                patientId,
                personnelId,
                sort,
            },
        });

    getPatientsByName = (namePart: string): Promise<PatientForVendorSearchDTO[]> =>
        httpApi.get({url: urls.PATIENT_BY_NAME, queryParams: {namePart}});

    getPersonnelsByName = (namePart: string): Promise<PersonnelDTO[]> =>
        httpApi.get({url: urls.PERSONNEL_BY_NAME, queryParams: {namePart}});

    activatePatient = ({id, data}: {id: number; data: PatientActivateDTO}): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.ACTIVATE_PATIENT(id), data});
    deactivatePatient = (patientId: number): Promise<SimpleMessageResponseDTO> =>
        httpApi.patch({url: urls.DEACTIVATE_PATIENT(patientId)});

    getTimezoneByZipCode = (zipCode: string): Promise<TimeZoneDTO> =>
        httpApi.get({url: urls.TIMEZONE_BY_ZIP_CODE, queryParams: {zipCode}});

    createPatient = (data: PatientDTO): Promise<CreatedResponseDTO> => httpApi.post({url: urls.PATIENTS, data});
    createDemoPatient = (data: PatientDTO): Promise<CreatedResponseDTO> =>
        httpApi.post({url: urls.DEMO_PATIENTS, data});

    getPatientAvailablePrecautions = (patientId: number): Promise<PrecautionDTO[]> =>
        httpApi.get({url: urls.PRECAUTIONS, queryParams: {patientId}});

    getPatientPrecautions = (patientId: number): Promise<PatientPrecautionOverviewDTO> =>
        httpApi.get({url: urls.PATIENT_PRECAUTIONS, queryParams: {patientId}});

    createPatientPrecautions = (data: PatientPrecautionCreateDTO): Promise<never> =>
        httpApi.post({url: urls.PATIENT_PRECAUTIONS, data});

    getPrecautionsAvailability = (id: number): Promise<boolean> =>
        httpApi.get({
            url: urls.HAS_ACTIVE_PATIENT_PRECAUTION(id),
        });

    discontinuePatientPrecaution = (precautionId: number): Promise<never> =>
        httpApi.patch({url: urls.DISCONTINUE_PATIENT_PRECAUTION(precautionId)});

    voidPatientPrecaution = ({precautionId, data}: {precautionId: number; data: VoidDetailsDTO}): Promise<never> =>
        httpApi.patch({url: urls.VOID_PATIENT_PRECAUTION(precautionId), data});

    updatePatientPrecaution = ({
        precautionId,
        data,
    }: {
        precautionId: number;
        data: PatientPrecautionUpdateDTO;
    }): Promise<PatientPrecautionDTO> => httpApi.put({url: urls.PATIENT_PRECAUTION(precautionId), data});

    getPatientPrecautionsHistory = (patientId: number): Promise<PatientPrecautionHistoryResponseDTO[]> =>
        httpApi.get({url: urls.PATIENT_PRECAUTIONS_HISTORY, queryParams: {patientId}});

    getDeactivationInfo = (patientId: number): Promise<PatientDeactivationInfo> =>
        httpApi.get({url: urls.DEACTIVATION_INFO(patientId)});

    getPatientAssignments = (queryParams: {
        clusterIds: number[];
        assignedNurseId?: number;
        assignedServiceCoordinatorId?: number;
        assignedPhysicianId?: number;
        assignedClinicianId?: number;
        assignedToMe?: boolean;
        showDemo?: boolean;
        showDischarged?: boolean;
        query?: string;
        products?: string;
        sponsors?: string;
        page?: number;
        size?: number;
        sort?: string;
    }): Promise<PagePatientAssignmentDTO> => httpApi.get({url: urls.PATIENT_ASSIGNMENTS, queryParams});

    getSinglePatientAssignments = (queryParams: {
        patientId: number;
        personnelType: 'NURSE' | 'SERVICE_COORDINATOR' | 'ATTENDING_PHYSICIAN' | 'RESPONSIBLE_CLINICIAN';
    }): Promise<PatientAvailableAssignmentsDTO> =>
        httpApi.get({
            url: urls.SINGLE_PATIENT_ASSIGNMENTS,
            queryParams,
        });

    getPatientAvailableAssignments = (queryParams: {
        clusterIds?: number[];
        patientIds?: number[];
    }): Promise<AvailableAssignmentsDTO> =>
        httpApi.get({
            url: urls.PATIENT_AVAILABLE_ASSIGNMENTS,
            queryParams,
        });

    multiShiftAssignment = (data: MultiAssignmentCreateDTO): Promise<SimpleMessageResponseDTO> =>
        httpApi.post({
            url: urls.MULTI_SHIFT_ASSIGNMENT,
            data,
        });

    getPatientAssignmentsHistory = (patientId: number): Promise<{[key: string]: ShiftAssignmentHistoryRecordDTO[]}> =>
        httpApi.get({
            url: urls.PATIENT_ASSIGNMENTS_HISTORY(patientId),
        });

    assignmentCheck = (patientId: number, podId: number): Promise<AssignmentCheckDTO> =>
        httpApi.get({
            url: urls.ASSIGMENT_CHECK(patientId),
            queryParams: {
                podId,
            },
        });

    getPatientsBasicInfo = (queryParams: {
        statuses?: 'ACTIVE' | 'INACTIVE';
        namePart?: string;
    }): Promise<PagePatientDropdownDataDTO> =>
        httpApi.get({
            url: urls.PATIENTS_BASIC,
            queryParams,
        });

    getPatientBasicInfo = (id: number): Promise<PatientBasicDataDTO> =>
        httpApi.get({
            url: urls.PATIENT_BASIC(id),
        });

    getVoidedReasons = (): Promise<VoidedReason[]> => httpApi.get({url: urls.VOIDED_REASONS});
}
const patientService = new PatientService();
export default patientService;
